@import 'react-alice-carousel/lib/alice-carousel.css';

@font-face {
  font-family: Playfair;
  src: url(../../public/fonts/Playfair.ttf);
}

@font-face {
  font-family: PlayfairBlack;
  src: url(../../public/fonts/PlayfairBlack.ttf);
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.app-charka-react-select hr {
  display: none;
}

.border-glow {
  animation: 4s infinite glow;
}

/*for boxes on rewards landing page */

.box {
  position: relative;
}

.box::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 20px;
  padding: 2px;
  background: linear-gradient(125deg, #000000, #00000040, #00000010, #00000000);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none; /* Ignore mouse interactions on the pseudo-element */
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.box:hover::before {
  opacity: 1;
  visibility: visible;
}

.boxLight {
  position: relative;
}

.boxLight::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 20px;
  padding: 2px;
  background: linear-gradient(125deg, #ffffff, #ffffff40, #ffffff10, #ffffff00);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none; /* Ignore mouse interactions on the pseudo-element */
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.boxLight:hover::before {
  opacity: 1;
  visibility: visible;
}

.boxLight.one::before {
  background: linear-gradient(
    125deg,
    #ffffff,
    #ffffff40,
    #ffffff10,
    #ffffff00
  ) !important;
}

.boxLight.two::before {
  background: linear-gradient(
    2000deg,
    #ffffff,
    #ffffff40,
    #ffffff10,
    #ffffff00
  ) !important;
}

.boxLight.three::before {
  background: linear-gradient(
    0deg,
    #ffffff,
    #ffffff40,
    #ffffff10,
    #ffffff00
  ) !important;
}

.white-svg {
  filter: invert(100%);
}

.pointer {
  cursor: pointer;
}

/*for floating rewards button*/
.button {
  transition: 0.5s;
  bottom: 0;
  right: 0;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  color: #fff;
  font-size: 24px;
  text-transform: uppercase;
  text-decoration: none;
  font-family: sans-serif;
  box-sizing: border-box;
  background: linear-gradient(90deg, #ffe02f, #f46f03, #ffc13b, #ffe02f);
  background-size: 400%;
  border-radius: 10000px;
  z-index: 1;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: animate 8s linear infinite;
}

.button:hover {
  cursor: pointer;
  transform: translate(-50%, -50%) scale(1.2);
}

@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}

.button:before {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1;
  background: linear-gradient(90deg, #ffe02f, #f46f03, #ffc13b, #ffe02f);
  background-size: 400%;
  border-radius: 40px;
  filter: blur(20px);
  animation: animate 8s linear infinite;
}

@keyframes glow {
  0% {
    border-color: red;
  }
  25% {
    border-color: blue;
  }
  75% {
    border-color: yellow;
  }
  100% {
    border-color: purple;
  }
}
@-webkit-keyframes glow {
  0% {
    border-color: red;
  }
  25% {
    border-color: blue;
  }
  75% {
    border-color: yellow;
  }
  100% {
    border-color: purple;
  }
}
